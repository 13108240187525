export function getYoutubeThumbnail(url, quality = false) {
  let videoId = "";
  if (url.includes("embed")) {
    videoId = url.split("/embed/")[1];
  } else {
    videoId = url.split("v=")[1];
  }
  return `https://img.youtube.com/vi/${videoId}/${
    quality ? "maxresdefault" : "mqdefault"
  }.jpg`;
}
export function getEmbedUrl(videoId) {
  return `https://www.youtube.com/embed/${videoId}`;
}
